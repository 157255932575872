// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/campaigns/inscricoes/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-form-js": () => import("/opt/build/repo/campaigns/inscricoes/src/templates/form.js" /* webpackChunkName: "component---src-templates-form-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/campaigns/inscricoes/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-geral-js": () => import("/opt/build/repo/campaigns/inscricoes/src/pages/geral.js" /* webpackChunkName: "component---src-pages-geral-js" */),
  "component---src-pages-males-js": () => import("/opt/build/repo/campaigns/inscricoes/src/pages/males.js" /* webpackChunkName: "component---src-pages-males-js" */)
}

